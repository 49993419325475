import useFetchRefWithOnClick from '@cms/hooks/useFetchRefWithOnClick'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import type { AnalyticsTargetType } from '@knauf-group/ct-shared-nextjs/web/analytics/types'
import type { TypeExternalReferenceSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types/TypeExternalReference'
import type { TypeLinkSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types/TypeLink'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { getFormattedReference } from '@knauf-group/ct-shared-nextjs/web/utils/utils'
import ArrowForward from '@mui/icons-material/ArrowForward'

import type { StyledWrappedLinkProps } from './StyledWrappedLink.types'

const StyledWrappedLink: React.FC<StyledWrappedLinkProps> = ({
  styledLinkReference: reference,
  analyticsComponentName,
  dataCy = 'styled-wrapped-link',
  children,
  ...props
}) => {
  const { siteStructure } = useGlobalContext()

  const targetReference =
    reference.sys.contentType?.sys?.id === CONTENTFUL_TYPES.EXTERNAL_REFERENCE
      ? (reference as ContentEntry<TypeExternalReferenceSkeleton>)
      : (reference as ContentEntry<TypeLinkSkeleton>)?.fields?.target

  const targetLabel = children?.toString()

  // there is a scenario where the targetReference is undefined which will result in formattedReference being null
  const formattedReference = getFormattedReference(targetReference, targetLabel, siteStructure)
  const targetType: AnalyticsTargetType = 'link'
  const formattedReferenceWithOnClick = useFetchRefWithOnClick(
    formattedReference,
    targetType,
    analyticsComponentName,
  )

  // BrandedWebLink should gracefully handle a null reference so we don't need to check for null here
  return (
    <WebLink
      reference={formattedReferenceWithOnClick}
      nextLinkComponent={props.nextLinkComponent}
      data-cy={`${dataCy}-anchor`}
      disabled={props.disabled}
      color="primary"
      endIcon={<ArrowForward sx={RTLIconStyles} />}
      {...props}
    >
      {children}
    </WebLink>
  )
}

export { StyledWrappedLink }
